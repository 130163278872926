:root {
  --color-fidee-web-footer: #505050;
  --color-fidee-white: white;
  --color-fidee-subtitle: #959595;
  --color-fidee-dark: #393939;
  --color-fidee-light-dark: #5F5F5F;
  --color-fidee-blue: #7190FF;
  --color-fidee-blue-hover: #98AFFF;
  --color-fidee-background: white;
  --color-fidee-placeholder: #CDCDCD;
  --color-fidee-border: #E4E4E4;
  --color-fidee-light-dark-hover: #505050;
  --color-fidee-border-hover: #EFEFEF;
  --color-fidee-error-red: #FF0000;
  --color-fidee-clear: rgba(0,0,0,0.0);
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
html, body, span, div, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video, textarea {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  text-indent: 0;
  list-style: none;
  text-decoration: none;
  vertical-align: baseline;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", 'Inter', 'Hiragino Sans', Meiryo, sans-serif;
  font-weight: normal;
}
