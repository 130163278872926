.lineBreak {
  white-space: pre-wrap;
}

.lineBreak > * {
  font-family: "游ゴシック体", "YuGothic", "游ゴシック", "Yu Gothic";
}

.lineBreak li {
  list-style: inside;
}
